/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  query Partners {\n    partners(options: { sort: [{ title: ASC }] }) {\n      type\n      title\n      link\n      picture {\n        url\n      }\n    }\n  }\n": types.PartnersDocument,
    "\n  query CurrentPoll {\n    polls(\n      where: { isOpen: true }\n      options: { sort: { createdAt: DESC }, limit: 1 }\n    ) {\n      id\n      title\n      options(options: { sort: { weight: ASC } }) {\n        id\n        title\n        votes\n      }\n      optionsAggregate {\n        node {\n          votes {\n            sum\n          }\n        }\n      }\n    }\n  }\n": types.CurrentPollDocument,
    "\n  mutation VoteOnPoll($pollId: ID!, $optionId: ID!) {\n    voteOnPoll(pollId: $pollId, optionId: $optionId)\n  }\n": types.VoteOnPollDocument,
    "\n  query PostLight($id: ID!) {\n    posts(where: { id: $id }) {\n      author {\n        id\n      }\n      category {\n        id\n      }\n    }\n  }\n": types.PostLightDocument,
    "\n  query Info($userId: ID!, $categoryId: ID) {\n    users(\n      where: {\n        OR: [\n          {\n            OR: [\n              { roles_INCLUDES: SUPER }\n              { roles_INCLUDES: ADMIN }\n              { roles_INCLUDES: AUTHOR_ADMIN }\n              { roles_INCLUDES: AUTHOR }\n            ]\n            deletedAt: null\n          }\n          { id: $userId }\n        ]\n      }\n    ) {\n      id\n      displayName\n      profile {\n        slug\n        gender\n        picture {\n          url\n        }\n      }\n    }\n    categories(where: { OR: [{ deletedAt: null }, { id: $categoryId }] }) {\n      id\n      title\n      cover {\n        url\n      }\n    }\n  }\n": types.InfoDocument,
    "\n  query Post($slug: String!, $type: PostType!, $id: ID!) {\n    posts(\n      where: {\n        OR: [{ slug: $slug, status: PUBLISHED, type: $type }, { id: $id }]\n      }\n    ) {\n      id\n      revisionsConnection(where: { edge: { status: CURRENT } }) {\n        edges {\n          node {\n            title\n            lead\n            body\n            publishedAt\n            author {\n              id\n              displayName\n              profile {\n                slug\n                gender\n                picture {\n                  url\n                }\n              }\n            }\n            category {\n              id\n              slug\n              title\n              cover {\n                url\n              }\n            }\n            cover {\n              url\n            }\n            tags {\n              id\n              slug\n              title\n            }\n          }\n        }\n      }\n    }\n  }\n": types.PostDocument,
    "\n  query Posts($where: PostWhere, $limit: Int!, $offset: Int!) {\n    postsAggregate(where: $where) {\n      count\n    }\n    posts(\n      where: $where\n      options: {\n        limit: $limit\n        offset: $offset\n        sort: [{ sticky: DESC }, { publishedAt: DESC }]\n      }\n    ) {\n      id\n      slug\n      revisionsConnection(where: { edge: { status: CURRENT } }) {\n        edges {\n          node {\n            title\n            lead\n            publishedAt\n            cover {\n              url\n            }\n            category {\n              id\n              title\n              slug\n            }\n          }\n        }\n      }\n    }\n  }\n": types.PostsDocument,
    "\n  query TopSongs {\n    requestedSongs(options: { limit: 5, sort: [{ rank: ASC }] }) {\n      album\n      artist\n      title\n      cover\n      rank\n      previousRank\n    }\n  }\n": types.TopSongsDocument,
    "\n  query TopTags {\n    tags(options: { limit: 20, sort: [{ usageCount: DESC }] }) {\n      title\n      slug\n      usageCount\n    }\n  }\n": types.TopTagsDocument,
    "\n  query Tags {\n    tags {\n      title\n    }\n  }\n": types.TagsDocument,
    "\n  query SlugType($slug: String!) {\n    categories(where: { slug: $slug }, options: { limit: 1 }) {\n      title\n      body\n    }\n    posts(\n      where: { slug: $slug, status: PUBLISHED, type: PAGE }\n      options: { limit: 1 }\n    ) {\n      id\n    }\n    users(where: { profile: { slug: $slug } }, options: { limit: 1 }) {\n      displayName\n      profile {\n        slug\n      }\n    }\n  }\n": types.SlugTypeDocument,
    "\n  query Polls($limit: Int!, $offset: Int!) {\n    pollsAggregate {\n      count\n    }\n    polls(\n      options: { sort: { createdAt: DESC }, limit: $limit, offset: $offset }\n    ) {\n      id\n      title\n      options(options: { sort: { weight: ASC } }) {\n        id\n        title\n        votes\n      }\n      optionsAggregate {\n        node {\n          votes {\n            sum\n          }\n        }\n      }\n    }\n  }\n": types.PollsDocument,
    "\n  query MainPage {\n    posts(\n      where: { status: PUBLISHED, type: ARTICLE }\n      options: { limit: 30, sort: [{ sticky: DESC }, { publishedAt: DESC }] }\n    ) {\n      id\n      slug\n      revisionsConnection(where: { edge: { status: CURRENT } }) {\n        edges {\n          node {\n            title\n            lead\n            publishedAt\n            cover {\n              url\n            }\n            category {\n              id\n              title\n              slug\n            }\n          }\n        }\n      }\n    }\n  }\n": types.MainPageDocument,
    "\n  query Feed {\n    posts(\n      where: { status: PUBLISHED, type: ARTICLE }\n      options: { limit: 20, sort: [{ sticky: DESC }, { publishedAt: DESC }] }\n    ) {\n      id\n      slug\n      revisionsConnection(where: { edge: { status: CURRENT } }) {\n        edges {\n          node {\n            title\n            lead\n            publishedAt\n            author {\n              displayName\n            }\n            cover {\n              url\n              mimeType\n              size\n            }\n            category {\n              id\n              title\n              slug\n            }\n          }\n        }\n      }\n    }\n  }\n": types.FeedDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Partners {\n    partners(options: { sort: [{ title: ASC }] }) {\n      type\n      title\n      link\n      picture {\n        url\n      }\n    }\n  }\n"): (typeof documents)["\n  query Partners {\n    partners(options: { sort: [{ title: ASC }] }) {\n      type\n      title\n      link\n      picture {\n        url\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query CurrentPoll {\n    polls(\n      where: { isOpen: true }\n      options: { sort: { createdAt: DESC }, limit: 1 }\n    ) {\n      id\n      title\n      options(options: { sort: { weight: ASC } }) {\n        id\n        title\n        votes\n      }\n      optionsAggregate {\n        node {\n          votes {\n            sum\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query CurrentPoll {\n    polls(\n      where: { isOpen: true }\n      options: { sort: { createdAt: DESC }, limit: 1 }\n    ) {\n      id\n      title\n      options(options: { sort: { weight: ASC } }) {\n        id\n        title\n        votes\n      }\n      optionsAggregate {\n        node {\n          votes {\n            sum\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation VoteOnPoll($pollId: ID!, $optionId: ID!) {\n    voteOnPoll(pollId: $pollId, optionId: $optionId)\n  }\n"): (typeof documents)["\n  mutation VoteOnPoll($pollId: ID!, $optionId: ID!) {\n    voteOnPoll(pollId: $pollId, optionId: $optionId)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query PostLight($id: ID!) {\n    posts(where: { id: $id }) {\n      author {\n        id\n      }\n      category {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  query PostLight($id: ID!) {\n    posts(where: { id: $id }) {\n      author {\n        id\n      }\n      category {\n        id\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Info($userId: ID!, $categoryId: ID) {\n    users(\n      where: {\n        OR: [\n          {\n            OR: [\n              { roles_INCLUDES: SUPER }\n              { roles_INCLUDES: ADMIN }\n              { roles_INCLUDES: AUTHOR_ADMIN }\n              { roles_INCLUDES: AUTHOR }\n            ]\n            deletedAt: null\n          }\n          { id: $userId }\n        ]\n      }\n    ) {\n      id\n      displayName\n      profile {\n        slug\n        gender\n        picture {\n          url\n        }\n      }\n    }\n    categories(where: { OR: [{ deletedAt: null }, { id: $categoryId }] }) {\n      id\n      title\n      cover {\n        url\n      }\n    }\n  }\n"): (typeof documents)["\n  query Info($userId: ID!, $categoryId: ID) {\n    users(\n      where: {\n        OR: [\n          {\n            OR: [\n              { roles_INCLUDES: SUPER }\n              { roles_INCLUDES: ADMIN }\n              { roles_INCLUDES: AUTHOR_ADMIN }\n              { roles_INCLUDES: AUTHOR }\n            ]\n            deletedAt: null\n          }\n          { id: $userId }\n        ]\n      }\n    ) {\n      id\n      displayName\n      profile {\n        slug\n        gender\n        picture {\n          url\n        }\n      }\n    }\n    categories(where: { OR: [{ deletedAt: null }, { id: $categoryId }] }) {\n      id\n      title\n      cover {\n        url\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Post($slug: String!, $type: PostType!, $id: ID!) {\n    posts(\n      where: {\n        OR: [{ slug: $slug, status: PUBLISHED, type: $type }, { id: $id }]\n      }\n    ) {\n      id\n      revisionsConnection(where: { edge: { status: CURRENT } }) {\n        edges {\n          node {\n            title\n            lead\n            body\n            publishedAt\n            author {\n              id\n              displayName\n              profile {\n                slug\n                gender\n                picture {\n                  url\n                }\n              }\n            }\n            category {\n              id\n              slug\n              title\n              cover {\n                url\n              }\n            }\n            cover {\n              url\n            }\n            tags {\n              id\n              slug\n              title\n            }\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query Post($slug: String!, $type: PostType!, $id: ID!) {\n    posts(\n      where: {\n        OR: [{ slug: $slug, status: PUBLISHED, type: $type }, { id: $id }]\n      }\n    ) {\n      id\n      revisionsConnection(where: { edge: { status: CURRENT } }) {\n        edges {\n          node {\n            title\n            lead\n            body\n            publishedAt\n            author {\n              id\n              displayName\n              profile {\n                slug\n                gender\n                picture {\n                  url\n                }\n              }\n            }\n            category {\n              id\n              slug\n              title\n              cover {\n                url\n              }\n            }\n            cover {\n              url\n            }\n            tags {\n              id\n              slug\n              title\n            }\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Posts($where: PostWhere, $limit: Int!, $offset: Int!) {\n    postsAggregate(where: $where) {\n      count\n    }\n    posts(\n      where: $where\n      options: {\n        limit: $limit\n        offset: $offset\n        sort: [{ sticky: DESC }, { publishedAt: DESC }]\n      }\n    ) {\n      id\n      slug\n      revisionsConnection(where: { edge: { status: CURRENT } }) {\n        edges {\n          node {\n            title\n            lead\n            publishedAt\n            cover {\n              url\n            }\n            category {\n              id\n              title\n              slug\n            }\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query Posts($where: PostWhere, $limit: Int!, $offset: Int!) {\n    postsAggregate(where: $where) {\n      count\n    }\n    posts(\n      where: $where\n      options: {\n        limit: $limit\n        offset: $offset\n        sort: [{ sticky: DESC }, { publishedAt: DESC }]\n      }\n    ) {\n      id\n      slug\n      revisionsConnection(where: { edge: { status: CURRENT } }) {\n        edges {\n          node {\n            title\n            lead\n            publishedAt\n            cover {\n              url\n            }\n            category {\n              id\n              title\n              slug\n            }\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query TopSongs {\n    requestedSongs(options: { limit: 5, sort: [{ rank: ASC }] }) {\n      album\n      artist\n      title\n      cover\n      rank\n      previousRank\n    }\n  }\n"): (typeof documents)["\n  query TopSongs {\n    requestedSongs(options: { limit: 5, sort: [{ rank: ASC }] }) {\n      album\n      artist\n      title\n      cover\n      rank\n      previousRank\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query TopTags {\n    tags(options: { limit: 20, sort: [{ usageCount: DESC }] }) {\n      title\n      slug\n      usageCount\n    }\n  }\n"): (typeof documents)["\n  query TopTags {\n    tags(options: { limit: 20, sort: [{ usageCount: DESC }] }) {\n      title\n      slug\n      usageCount\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Tags {\n    tags {\n      title\n    }\n  }\n"): (typeof documents)["\n  query Tags {\n    tags {\n      title\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query SlugType($slug: String!) {\n    categories(where: { slug: $slug }, options: { limit: 1 }) {\n      title\n      body\n    }\n    posts(\n      where: { slug: $slug, status: PUBLISHED, type: PAGE }\n      options: { limit: 1 }\n    ) {\n      id\n    }\n    users(where: { profile: { slug: $slug } }, options: { limit: 1 }) {\n      displayName\n      profile {\n        slug\n      }\n    }\n  }\n"): (typeof documents)["\n  query SlugType($slug: String!) {\n    categories(where: { slug: $slug }, options: { limit: 1 }) {\n      title\n      body\n    }\n    posts(\n      where: { slug: $slug, status: PUBLISHED, type: PAGE }\n      options: { limit: 1 }\n    ) {\n      id\n    }\n    users(where: { profile: { slug: $slug } }, options: { limit: 1 }) {\n      displayName\n      profile {\n        slug\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Polls($limit: Int!, $offset: Int!) {\n    pollsAggregate {\n      count\n    }\n    polls(\n      options: { sort: { createdAt: DESC }, limit: $limit, offset: $offset }\n    ) {\n      id\n      title\n      options(options: { sort: { weight: ASC } }) {\n        id\n        title\n        votes\n      }\n      optionsAggregate {\n        node {\n          votes {\n            sum\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query Polls($limit: Int!, $offset: Int!) {\n    pollsAggregate {\n      count\n    }\n    polls(\n      options: { sort: { createdAt: DESC }, limit: $limit, offset: $offset }\n    ) {\n      id\n      title\n      options(options: { sort: { weight: ASC } }) {\n        id\n        title\n        votes\n      }\n      optionsAggregate {\n        node {\n          votes {\n            sum\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query MainPage {\n    posts(\n      where: { status: PUBLISHED, type: ARTICLE }\n      options: { limit: 30, sort: [{ sticky: DESC }, { publishedAt: DESC }] }\n    ) {\n      id\n      slug\n      revisionsConnection(where: { edge: { status: CURRENT } }) {\n        edges {\n          node {\n            title\n            lead\n            publishedAt\n            cover {\n              url\n            }\n            category {\n              id\n              title\n              slug\n            }\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query MainPage {\n    posts(\n      where: { status: PUBLISHED, type: ARTICLE }\n      options: { limit: 30, sort: [{ sticky: DESC }, { publishedAt: DESC }] }\n    ) {\n      id\n      slug\n      revisionsConnection(where: { edge: { status: CURRENT } }) {\n        edges {\n          node {\n            title\n            lead\n            publishedAt\n            cover {\n              url\n            }\n            category {\n              id\n              title\n              slug\n            }\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Feed {\n    posts(\n      where: { status: PUBLISHED, type: ARTICLE }\n      options: { limit: 20, sort: [{ sticky: DESC }, { publishedAt: DESC }] }\n    ) {\n      id\n      slug\n      revisionsConnection(where: { edge: { status: CURRENT } }) {\n        edges {\n          node {\n            title\n            lead\n            publishedAt\n            author {\n              displayName\n            }\n            cover {\n              url\n              mimeType\n              size\n            }\n            category {\n              id\n              title\n              slug\n            }\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query Feed {\n    posts(\n      where: { status: PUBLISHED, type: ARTICLE }\n      options: { limit: 20, sort: [{ sticky: DESC }, { publishedAt: DESC }] }\n    ) {\n      id\n      slug\n      revisionsConnection(where: { edge: { status: CURRENT } }) {\n        edges {\n          node {\n            title\n            lead\n            publishedAt\n            author {\n              displayName\n            }\n            cover {\n              url\n              mimeType\n              size\n            }\n            category {\n              id\n              title\n              slug\n            }\n          }\n        }\n      }\n    }\n  }\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;